import React, { FormEvent, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { differenceBetweenTimes, formatToLocalTime, getAccountedForDetails } from '../../lib/helpers/helpers';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import PERMISSIONS_CONTEXT from 'GuardianPermissions/lib/config/permissionsContext';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import { DateTime } from 'luxon';
import { addLeaderAsync, searchIncidentAsync } from '../../slices/incidents/incidentDetailsSlice';
import { useAppDispatch } from '../../context/store';
import { ComposedWithAuthAndPermissionsProps, PermissionsContext, UserContext } from '../../lib/types';
import { compose } from 'redux';
import { DOMAINS, TABLE_REFRESH_TIME } from '../../lib/constants';
import { useTimeoutFn } from 'react-use';
import ToggleAutoReloadSwitch from '../Buttons/ToggleAutoReloadSwitch';
import useValidatedLogins from '../../hooks/useValidateLogins';

const styles = {
    cardHeaderStyles: {
        backgroundColor: '#0D4AFF',
        color: 'white',
        fontWeight: '600',
        fontSize: '1rem',
        width: '100%'
    },
    cardBodyStyles: {
        color: '#000000',
        fontSize: '1rem',
        fontWeight: '400'
    },
    addScannerButton: {
        backgroundColor: '#0D4AFF',
        border: '1px solid',
        borderRadius: '4px',
        width: '50px',
        height: '32px'
    }
};

const IncidentDetailsSideBar = ({ permissionsContext }: ComposedWithAuthAndPermissionsProps): JSX.Element => {
    const { incident, isLoadingIncident, isAddingLeader } = useSelector(
        (state: RootState) => state.incidentDetails
    );
    const [timestamp, setTimestamp] = useState(formatToLocalTime(DateTime.utc()));
    const { logins, setLogins, validatedLogins } = useValidatedLogins();
    const dispatch = useAppDispatch();

    const reloadSidebar = (): void => {
        if (incident) {
            dispatch(searchIncidentAsync(incident.id));
        }
    };
    const [isReady, cancel, reset] = useTimeoutFn(reloadSidebar, TABLE_REFRESH_TIME);

    if (isReady()) {
        reset();
    }

    useEffect(() => {
        setTimestamp(formatToLocalTime(DateTime.utc()));
    }, [incident]);

    const addLeaderOnSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setLogins('');
        if (incident?.id && validatedLogins?.validLogins[0]) {
            dispatch(addLeaderAsync(incident.id, validatedLogins.validLogins[0]));
        }
    };

    let formattedEndTime = '';
    if (incident) {
        let duration;
        if (!incident.isOpen) {
            duration = differenceBetweenTimes(incident.startTime, incident.endTime);
            formattedEndTime = formatToLocalTime(DateTime.fromISO(incident.endTime, { zone: 'utc' }));
        } else {
            duration = differenceBetweenTimes(incident.startTime);
        }
        const { percentageAccountedFor, totalOnSite, totalAccountedFor } = getAccountedForDetails(
            incident.totalBadgeHolders,
            incident.totalVisitors,
            incident.totalBadgeHoldersAccountedFor,
            incident.totalVisitorsAccountedFor
        );
        const formattedStartTime = formatToLocalTime(DateTime.fromISO(incident.startTime, { zone: 'utc' }));
        return (
            <DimmedLoader active={isLoadingIncident}>
                <Card>
                    <Card.Header style={styles.cardHeaderStyles}>
                        Incident Details as of
                        <br />
                        {timestamp}
                        <hr color='white' />
                        <Row>
                            <Col>
                                <span>Sites</span>: {incident.sites?.map((site) => site.siteName)?.join(', ')}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Incident ID:</span> {incident.id}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body style={styles.cardBodyStyles}>
                        <Row className='mb-2'>
                            <Col>
                                <span>
                                    <strong>Incident Type: </strong>
                                    {incident.incidentNewType?.name && incident.incidentSubType?.name
                                        ? `${incident.incidentNewType.name} - ${incident.incidentSubType.name}`
                                        : incident.incidentType.name}
                                </span>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col>
                                <span>
                                    <strong>Event Owner:</strong> {incident.eventOwner}
                                </span>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col>
                                <span>
                                    <strong>Incident Length:</strong> {duration}
                                </span>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col>
                                <span>
                                    <strong>Start Time:</strong> {formattedStartTime}
                                </span>
                            </Col>
                        </Row>
                        {formattedEndTime ? (
                            <Row className='my-3'>
                                <Col>
                                    <span>
                                        <strong>End Time:</strong> {formattedEndTime}
                                    </span>
                                </Col>
                            </Row>
                        ) : null}
                        <Row className='my-3'>
                            <Col>
                                <span>
                                    <strong>On-Site:</strong> {totalOnSite}
                                </span>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <span>
                                    <strong>Accounted For:</strong> {totalAccountedFor} (
                                    {percentageAccountedFor})
                                </span>
                            </Col>
                        </Row>
                        <Row className='mt-5 mb-1'>
                            <Col>
                                <span>Add Scanner</span>
                            </Col>
                        </Row>
                        <DimmedLoader active={permissionsContext.isFetchingPermissions[DOMAINS.DEFAULT]}>
                            <Row>
                                <Col>
                                    <Form onSubmit={addLeaderOnSubmit}>
                                        <Form.Row>
                                            <Form.Group as={Col} md='8'>
                                                <Form.Control
                                                    className='mb-8 float-right'
                                                    onChange={(event) => setLogins(event.target.value)}
                                                    placeholder='Login'
                                                    value={logins}
                                                    isInvalid={!!validatedLogins.invalidLogins.length}
                                                />
                                                <Form.Control.Feedback
                                                    className='mb-2 mr-sm-2'
                                                    type='invalid'
                                                >
                                                    The login you entered is invalid:{' '}
                                                    {validatedLogins.invalidLogins[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md='2'>
                                                <Button
                                                    type='submit'
                                                    className='mb-2 float-left'
                                                    disabled={
                                                        isAddingLeader ||
                                                        !validatedLogins.validLogins.length ||
                                                        !!validatedLogins.invalidLogins.length
                                                    }
                                                    size='sm'
                                                    style={styles.addScannerButton}
                                                >
                                                    Add
                                                </Button>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </Col>
                            </Row>
                            <ToggleAutoReloadSwitch
                                cancel={() => {
                                    cancel();
                                }}
                                reset={() => {
                                    reset();
                                }}
                            />
                        </DimmedLoader>
                    </Card.Body>
                </Card>
            </DimmedLoader>
        );
    } else {
        return <DimmedLoader active={isLoadingIncident} />;
    }
};

const mapPermissionStateToProps = (state: PermissionsContext): PermissionsContext => {
    const { permissionsContext } = state;
    return {
        permissionsContext
    };
};

const mapAuthStateToProps = (state: UserContext): UserContext => {
    const { userContext } = state;
    return {
        userContext
    };
};

export default compose(
    connect(mapPermissionStateToProps, null, null, { context: PERMISSIONS_CONTEXT }),
    connect(mapAuthStateToProps, null, null, { context: AUTH_CONTEXT })
)(IncidentDetailsSideBar) as React.ComponentType;
