import React, { useEffect } from 'react';
import '../../css/FacManKeysTable.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaFilter } from 'react-icons/fa';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { FacManKeysPropMap, FacManKeysTitlePropMap } from '../../lib/dictionaries/PermissionsTeamsDictionary';
import { NoDataIndication } from './NoDataIndication';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import { CONFIRM_DELETE_RESOLVER_GROUPS_KEY_MESSAGE } from '../../lib/constants';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FacManKeysEntries } from '../../lib/types';
import { getFacManKeysAsync, removeFacManKeyAsync } from '../../slices/admin/facManKeysSlice';
import { useAppDispatch } from '../../context/store';
import Swal from 'sweetalert2';
import FacManKeyAdd from '../Modals/FacManKeyAdd';

const styles = {
    addKeyButton: {
        backgroundColor: '#EBECFF',
        width: '90px',
        height: '30px',
        border: 'none',
        color: '#0036C8',
        fontSize: '9pt',
        fontWeight: '500',
        borderRadius: '4px',
        float: 'right' as const,
        marginRight: '8px',
        marginTop: '2px'
    },
    rowDeleteButton: {
        color: '#0D4AFF',
        padding: 0,
        alignSelf: 'flex-start',
        fontSize: '14px',
        marginTop: '-3px'
    }
};

// eslint-disable-next-line
const FacManKeysTable = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [tableFilters, setTableFilters] = useToggle(false);
    const [openAddKey, setOpenAddKey] = useToggle(false);
    const { keys, isGettingFacManKeys } = useSelector((state: RootState) => state.facManKeys);

    useEffect(() => {
        if (!keys) {
            dispatch(getFacManKeysAsync());
        }
    }, []);
    countries.registerLocale(localeEn);

    const handleDelete = async (name: string): Promise<void> => {
        const { value: confirmed } = await Swal.fire({
            title: CONFIRM_DELETE_RESOLVER_GROUPS_KEY_MESSAGE,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#dc3645'
        });
        if (confirmed && name != null) {
            dispatch(removeFacManKeyAsync(name));
        }
    };

    // eslint-disable-next-line
    const rowDelete = (value: any, row: any) => {
        return (
            <Button
                title='Delete'
                style={styles.rowDeleteButton}
                variant='link'
                onClick={() => {
                    handleDelete(row.name);
                }}
            >
                Delete
            </Button>
        );
    };

    const facManKeysColumns = [
        {
            dataField: FacManKeysPropMap.name,
            text: FacManKeysTitlePropMap.name,
            sort: true,
            sortFunc(a: string, b: string, order: string) {
                return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
            },
            filter: tableFilters
                ? textFilter({
                      placeholder: FacManKeysTitlePropMap.name + '...'
                  })
                : false,
            style: {
                cursor: 'pointer'
            }
        },
        {
            dataField: FacManKeysPropMap.lastUpdated,
            text: FacManKeysTitlePropMap.dateLastModified
        },
        {
            dataField: FacManKeysPropMap.updatedBy,
            text: FacManKeysTitlePropMap.user
        },
        {
            dataField: FacManKeysPropMap.remove,
            text: FacManKeysTitlePropMap.remove,
            formatter: rowDelete
        }
    ];

    const defaultSorted = [
        {
            dataField: FacManKeysPropMap.name,
            order: 'desc'
        }
    ];

    const renderedFacManKeys = keys.map((group: FacManKeysEntries) => {
        return {
            [FacManKeysPropMap.name]: group.name,
            [FacManKeysPropMap.lastUpdated]: new Date(group.lastUpdated).toLocaleString(),
            [FacManKeysPropMap.updatedBy]: group.updatedBy
        };
    });

    return (
        <div id='facManKeys' style={{ width: '90%' }}>
            <label style={{ fontSize: '1.7em', fontStyle: 'bold' }}>Facility Manager Keys</label>
            <Button
                title='Filter and Sort'
                onClick={() => setTableFilters((prevTableFilters: boolean) => !prevTableFilters)}
                size='sm'
                variant='outline'
                style={{ float: 'right' }}
            >
                <FaFilter />
            </Button>
            <Button
                title='Add Key'
                size='sm'
                type='Button'
                onClick={() => {
                    setOpenAddKey(true);
                }}
                className='btn btn-secondary btn-sm'
                style={styles.addKeyButton}
            >
                Add Key
            </Button>
            {openAddKey && <FacManKeyAdd isVisible={setOpenAddKey} />}
            <br />
            <ToolkitProvider
                keyField={FacManKeysPropMap.name}
                data={renderedFacManKeys}
                columns={facManKeysColumns}
            >
                {//eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props: any) => {
                    return (
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            filter={filterFactory()}
                            bordered={false}
                            hover
                            wrapperClasses='table-responsive'
                            keyField={FacManKeysPropMap.name}
                            data={renderedFacManKeys}
                            columns={facManKeysColumns}
                            noDataIndication={NoDataIndication(isGettingFacManKeys)}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({})}
                        />
                    );
                }}
            </ToolkitProvider>
        </div>
    );
};
export default FacManKeysTable;
