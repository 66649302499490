import React, { SetStateAction, useEffect, useState } from 'react';
import {
    DataGridPro,
    GridPagination,
    GridSortModel,
    GridSortDirection,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTimeoutFn } from 'react-use';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import {
    DATA_GRID_COMMON_COMPONENTS_PROPS_STYLES,
    DATA_GRID_COMMON_SX_STYLES,
    DEFAULT_TABLE_SORTING_ORDER,
    SIZE_PER_PAGE_LIST,
    TABLE_REFRESH_TIME
} from '../../lib/constants';
import { formatToLocalTime } from '../../lib/helpers/helpers';
import { searchIncidentAuditTrailAsync } from '../../slices/incidents/incidentDetailsSlice';
import ToggleAutoReloadSwitch from '../Buttons/ToggleAutoReloadSwitch';
import { incidentAuditTrailColumns, defaultSortModel, DEFAULT_PAGE_SIZE } from './IncidentAuditTrailProps';

const IncidentAuditTrailDataGrid: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { incidentId } = useParams<{ incidentId: string }>();
    const [timestamp, setTimestamp] = useState(formatToLocalTime(DateTime.utc()));
    const [selectedPageSize, setSelectedPageSize] = React.useState(DEFAULT_PAGE_SIZE);
    const [tableSort, setTableSort] = React.useState<GridSortModel>(defaultSortModel);
    const { auditTrail, isSearchingIncidentAuditTrail } = useSelector(
        (state: RootState) => state.incidentDetails
    );

    const refreshTable = (): void => {
        dispatch(searchIncidentAuditTrailAsync({ incidentId }));
    };

    const [isReady, cancel, reset] = useTimeoutFn(refreshTable, TABLE_REFRESH_TIME);

    if (isReady()) {
        reset();
    }

    useEffect(() => {
        if (incidentId) {
            dispatch(searchIncidentAuditTrailAsync({ incidentId }));
        }
    }, []);

    useEffect(() => {
        setTimestamp(formatToLocalTime(DateTime.utc()));
    }, [auditTrail]);

    const tableSortChangeCallback = (newSort: GridSortModel): void => {
        setTableSort(newSort);
    };

    const tablePageSizeChange = (event: SetStateAction<number>): void => {
        setSelectedPageSize(event);
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h6>Audit Trail as of {timestamp} </h6>
                <div className='ml-auto'>
                    <ToggleAutoReloadSwitch
                        label='For Table'
                        cancel={() => {
                            cancel();
                        }}
                        reset={() => {
                            reset();
                        }}
                    />
                </div>
            </div>
            <DataGridPro
                rows={auditTrail}
                columns={incidentAuditTrailColumns}
                pagination
                pageSize={selectedPageSize}
                rowsPerPageOptions={SIZE_PER_PAGE_LIST}
                sortingOrder={DEFAULT_TABLE_SORTING_ORDER as GridSortDirection[]}
                onSortModelChange={tableSortChangeCallback}
                onPageSizeChange={tablePageSizeChange}
                sortModel={tableSort}
                loading={isSearchingIncidentAuditTrail}
                autoHeight
                components={{
                    Toolbar: () => <GridToolbar />,
                    Pagination: GridPagination
                }}
                componentsProps={DATA_GRID_COMMON_COMPONENTS_PROPS_STYLES}
                disableColumnMenu={true}
                disableSelectionOnClick
                sx={DATA_GRID_COMMON_SX_STYLES}
            />
        </div>
    );
};

export default IncidentAuditTrailDataGrid;
