export const MINIMUM_RESULTS_TO_SHOW_PAGINATION_OPTIONS = 11;

export const START_PAGE = 1;

export const SIZE_PER_PAGE_LIST = [10, 25, 50, 100];

export const SIZE_PER_HISTORY_PAGE_LIST = [10, 25, 50];

export const DESC = 'desc';

export const ASC = 'asc';

export const DEFAULT_TABLE_SORTING_ORDER = [DESC, ASC];

export const TABLE_REFRESH_TIME = 30000;

export const RESPONDER_DASHBOARD_REFRESH_MS_TIME = 60000;

export const RESPONDER_DASHBOARD_REFRESH_S_TIME = 60;

export const RESPONDER_REFRESH_BUFFER_SECONDS = 10;

export const SIXTY_SECONDS = 60;

export const ONE_SECOND = 1000;

export const COUNTDOWN_LENGTH = 5;

/* Default days value when defaultAnonymizationSetting does not return any value */
export const DEFAULT_OVERRIDE_DAYS = 2;

export const DATE_FORMAT = 'dd MMM yyyy HH:mm:ss ZZZZ';

export const DB_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const PHONETOOL_LINK_PREFIX = 'https://phonetool.amazon.com/users/';

export const ADMIN_DIRECTORY_LINK_PREFIX = 'https://admindirectory.corp.amazon.com/users/';

export const DOMAINS = {
    DEFAULT: '*'
};

export const NA = 'N/A';

export const RALLY_POINT_PERMISSIONS = {
    EVENT_OWNER: {
        OBJECT: '/rallypoint',
        ACTION: 'eventOwnerAccess'
    },
    BUSINESS: {
        OBJECT: '/rallypoint',
        ACTION: 'businessAccess'
    },
    ADMIN: {
        OBJECT: '/rallypoint',
        ACTION: 'adminAccess'
    },
    SHOW_OSR: {
        OBJECT: '/rallypoint',
        ACTION: 'showOSR'
    },
    SHOW_INCIDENT_DETAILS: {
        OBJECT: '/rallypoint',
        ACTION: 'showIncidentDetails'
    },
    SHOW_INCIDENT_HISTORY: {
        OBJECT: '/rallypoint',
        ACTION: 'showIncidentHistory'
    },
    SHOW_ADMIN: {
        OBJECT: '/rallypoint',
        ACTION: 'showAdmin'
    },
    TEST_ACCESS: {
        OBJECT: '/rallypoint',
        ACTION: 'testAccess'
    }
};

export const RALLY_POINT_ROLES = {
    ADMIN: 'Admin',
    BUSINESS: 'Business',
    EVENT_OWNER: 'EventOwner',
    USER: 'User'
};

export const STATUS_COLORS = {
    GREEN: 'green',
    GREY: 'grey'
};

export const RP_WIKI_URL =
    'https://w.amazon.com/bin/view/New_Technologies_for_Security_(NT4S)/Rally_Point/User_Guide';

export const ERROR_AUTO_CLOSE = 10000;

export const INCIDENT_TYPES = {
    DRILL: 'Drill',
    SHELTER_IN_PLACE: 'Shelter in Place',
    EVACUATION: 'Evacuation'
};

export const INCIDENT_NEW_TYPES = {
    REAL_EMERGENCY: 'Real Emergency',
    DRILL: 'Drill',
    DEMO: 'Demo'
};

export const INCIDENT_SUB_TYPES = {
    EVACUATION: 'Evacuation',
    SHELTER_IN_PLACE: 'Shelter in Place'
};

export const PHONE_ANONYMIZATION_EVENT_TYPES = {
    REAL_EMERGENCY_EVACUATION: 'Real Emergency - Evacuation',
    REAL_EMERGENCY_SHELTER_IN_PLACE: 'Real Emergency - Shelter in Place',
    DRILL_EVACUATION: 'Drill - Evacuation',
    DRILL_SHELTER_IN_PLACE: 'Drill - Shelter in Place',
    DEMO_EVACUATION: 'Demo - Evacuation',
    DEMO_SHELTER_IN_PLACE: 'Demo - Shelter in Place'
};

export const INVALID_DAYS_MESSAGE = 'You must enter a value from 0 to 365!';

export const CONFIRM_ADD_OVERRIDE_MESSAGE = 'Are you sure you want to add this override?';

export const CONFIRM_EDIT_OVERRIDE_MESSAGE = 'Are you sure you want to edit this override?';

export const CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE =
    'Are you sure you want to change the default override?';

export const CONFIRM_DELETE_OVERRIDE_MESSAGE = 'Are you sure you want to delete this override?';

export const OVERRIDE_EXISTS_MESSAGE = 'An override already exists for this country!';

export const NEVER_ANONYMIZE_MESSAGE = 'Never';

export const ROLES_PERMISSIONS_MESSAGES = {
    USERS_TAB_MSG:
        'All Amazonians (anyone with a login, including contractors) will at a minimum have user-level access. Changes to tab access and to the following key features must go through the software development lifecycle.',
    EVENT_OWNERS_TAB_MSG:
        'Access to the Event Owner role must be requested via Site admins, who can get an ANT group associated with a permission team, mapped to the Rally point Event owner role under Resolver group in Facility Manager.',
    BUSINESS_TEAMS_TAB_MSG:
        'Business Teams have the ability to view PII at all times for all sites globally in Rally Point Web. Business Team users will default to either the User or Event Owner role in Rally Point Mobile, depending on their People Portal and Facility Manager associations. Access for new Permissions Teams to get added as business team role should be requested via SIM and have L6+ approval.',
    ADMINS_TAB_MSG: 'Admins have access to all tabs and all features and can see all PII at all times.',
    FOOTER_NOTE_ASSOCIATED:
        '*Associated = any site you are associated with in people portal or any site for which you are listed as an IMT member in Facility Manager'
};

export const EVENT_OWNERS_FOOTNOTES = {
    TITLE: 'Other populations who automatically get the Event Owner role:',
    NOTE1: 'All blue badge employees marked as a manager in People API (PAPI)',
    NOTE2: 'Anyone on an Incident Management Team (IMT) as listed in Facility Manager'
};

export const DEFAULT_ANONYMIZE_TOGGLE = {
    OFF: 'Off',
    ON: 'On'
};
export const ROLES = {
    ADMIN: 'admin',
    BUSINESS: 'business',
    EVENTOWNER: 'eventOwner',
    USER: 'user'
};
export const VIEW_ROLE_MAP = {
    admin: 'Admin',
    business: 'Business Team',
    eventOwner: 'Event Owner',
    user: 'User'
};
export const PERMISSIONS_TEAMS_EXISTS_MESSAGES = 'This Permissions team exists already!';

export const CONFIRM_ADD_PERMISSIONS_TEAMS_MESSAGE = 'Are you sure you want to add this permission team?';

export const CONFIRM_DELETE_PERMISSIONS_TEAMS_MESSAGE =
    'Are you sure you want to delete this permission team?';

export const RESOLVER_GROUP_KEY_EXISTS_MESSAGES = 'This Facility Manager RESOLVER GROUPS key exists already!';

export const CONFIRM_ADD_RESOLVER_GROUP_KEY_MESSAGE =
    'Are you sure you want to add this RESOLVER GROUPS key?';

export const CONFIRM_DELETE_RESOLVER_GROUPS_KEY_MESSAGE =
    'Are you sure you want to delete this RESOLVER GROUPS key?';

export const INVALID_ABACUSID_MESSAGE = 'Invalid Abacus Id : ';

export const VALIDATING_MESSAGE = 'Validating...';

export const TABLE_FILTER_TIP = 'Filtering performs OR with same columns, AND with different columns';

export const FILTER_TIP_ICON_SIZE = 20;

export const RP_BLUE = '#0D4AFF';

export const TAB_COLOR = '#007bff';

export const INVALID_LOGIN = 'INVALID LOGIN';

export const MAX_CARDHOLDERS_PAGE_SIZE = 2000;

export const ACTIVE_GROUP_EMP_STATUS = 'A';

export const CARDHOLDER_API_RESPONSE_SCHEMA: { [key: string]: string } = {
    RESULTS: 'results',
    EMPLOYEES: 'employees',
    DATA: 'data',
    EMPID: 'empId',
    LOGIN: 'login',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'primaryEmail',
    PRIMARY_PHONE: 'primaryPhone',
    IS_PRIMARY_PHONE_PRIVATE: 'isPrimaryPhonePrivate',
    MOBILE_PHONE: 'mobilePhone',
    IS_MOBILE_PHONE_PRIVATE: 'isMobilePhonePrivate',
    HOME_PHONE: 'homePhone',
    IS_HOME_PHONE_PRIVATE: 'isHomePhonePrivate',
    OFFICE_PHONE: 'officePhone',
    IS_OFFICE_PHONE_PRIVATE: 'isOfficePhonePrivate',
    TITLE: 'title',
    LOCATION: 'location',
    PERSONID: 'personId',
    FAILURES: 'failures',
    LOGINS: 'logins',
    BARCODE: 'barcode',
    BARCODES: 'barcodes',
    BADGE_COLOR: 'badgeColor',
    MANAGER: 'manager',
    IS_MANAGER: 'isManager',
    MANAGER_FIRST_NAME: 'managerFirstName',
    MANAGER_LAST_NAME: 'managerLastName',
    ENCODED_BADGE_PHOTO: 'encodedBadgePhoto',
    IMG_URL: 'imgURL',
    DEVICE_NAME: 'deviceName',
    EVENT_TIMESTAMP: 'eventTimestamp',
    JOB_LEVEL: 'jobLevel'
};

export const RESPONDER_STRINGS = {
    EMPLOYEE_INFORMATION: 'Employee Information',
    MANAGER_INFORMATION: 'Manager Information',
    MANAGER: 'Manager',
    MANAGER_ALIAS: 'Manager alias',
    BADGE: 'Badge',
    LAST_BADGE_POINT: 'Last badge point',
    LAST_BADGE_TIME: 'Last badge time',
    SEE_ALL: 'See all',
    MAIN_TITLE: 'On-Site Emergency Responders',
    IMT_SECTION_TITLE: 'Incident Management Team (IMT)',
    WHS_SECTION_TITLE: 'Workplace, Health, and Safety (WHS)',
    FIRST_RESPONDERS_SECTION_TITLE: 'First Responders',
    IMT: 'imt',
    WHS: 'whs',
    FIRST_RESPONDERS: 'firstResponders',
    MAXIMUM_SITES: 5,
    INVALID_SEARCH_LENGTH: 2,
    SITE_POPOVER_MESSAGE: 'You cannot add more than 5 sites at a time.',
    WAIT_MESSAGE: 'This may take a few seconds.',
    NO_INFO_MAIN: 'No information to present',
    PAGE_INFO_MESSAGE: `This report is being pulled from Facility Manager's ”On-Site Emergency Responders“, Workplace Health and Safety (WHS) content sections and Mass Notification groups. Please update these to see on-site responders in RallyPoint.`,
    NO_RESULT_BASE_MESSAGE: 'The filters that are being applied do not have data associated to them',
    NO_RESULT_AVAILABLE_MESSAGE: 'Select from the facilities above to view the Emergency Response Dashboard',
    DISPLAY_FULLSCREEN: 'Display Full Screen',
    EXPORT: 'Export',
    FULL_EXPORT: 'Export all data',
    FILTERED_EXPORT: 'Export data as displayed',
    GO_BACK: 'Go back'
};

export const PII_MASK = '******';

export const DATA_GRID_COMMON_TEXT_STYLES = {
    fontFamily: 'Inter',
    textTransform: 'capitalize'
};

export const DATA_GRID_COMMON_COMPONENTS_PROPS_STYLES = {
    filterPanel: {
        linkOperators: [],
        filterFormProps: {
            operatorInputProps: {
                disabled: true,
                sx: { display: 'none' }
            }
        }
    },
    panel: {
        sx: {
            '& .MuiTypography-root': DATA_GRID_COMMON_TEXT_STYLES,
            '& .MuiButton-text': DATA_GRID_COMMON_TEXT_STYLES,
            '& .MuiInputBase-root': DATA_GRID_COMMON_TEXT_STYLES,
            '& .MuiFormLabel-root': DATA_GRID_COMMON_TEXT_STYLES
        }
    },
    footer: {
        sx: {
            '& .MuiToolbar-root': DATA_GRID_COMMON_TEXT_STYLES,
            '& .MuiSelect-select': DATA_GRID_COMMON_TEXT_STYLES,
            '& .MuiTablePagination-selectLabel': { ...DATA_GRID_COMMON_TEXT_STYLES, marginTop: '14px' },
            '& .MuiTablePagination-displayedRows': { ...DATA_GRID_COMMON_TEXT_STYLES, marginTop: '14px' }
        }
    }
};

export const DATA_GRID_COMMON_SX_STYLES = {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        ['& .MuiDataGrid-columnHeaderTitle']: {
            fontSize: '0.8rem',
            fontWeight: '600',
            overflow: 'visible',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
            lineHeight: '1.1'
        }
    },
    '& .MuiDataGrid-row': {
        fontSize: '0.8rem',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        ['& .MuiDataGrid-cell']: {
            overflowWrap: 'break-word',
            whiteSpace: 'normal'
        }
    },
    '& .MuiDataGrid-row:not': {
        fontSize: '0.8rem',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        ['& .MuiDataGrid-cell']: {
            overflowWrap: 'break-word',
            whiteSpace: 'normal'
        }
    },
    '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        lineHeight: '1.1'
    },
    '& .MuiButton-text': {
        fontFamily: 'Inter, sans-serif',
        textTransform: 'capitalize',
        fontWeight: '400',
        color: '#0D4AFF'
    },
    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Inter, sans-serif',
        textTransform: 'capitalize',
        fontWeight: '400'
    },
    '& .MuiDataGrid-cell': {
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400'
    },
    border: '2px solid #EAECF0',
    borderRadius: '10px',
    padding: '10px 10px 10px 10px'
};

export const RESPONDER_SITE_URL_SEPARATOR = '+';
